import $ from 'dom7';

function initFramework7Jp(){

  // docs-indexの自動生成
  addDocsIndex();

  // aイベントの自動target="_blank"化
  openExternalLink();
}
/**
 * docs-indexが、pugのbuild時に日本語だとエラーとなるので、
 * 面倒なので、jsで挿入する
 */
function addDocsIndex(){

  // docs配下のみインデックスを入れる。と思ったけど一旦すべてでいいかな。
  // if ( !locationl.href.indexOf('/docs/') === -1){
  // }

  // docs-content直下h1から始める
  var $h1 = $('.docs-content > h1').eq(0);

  // h2の検索
  var h2IdNum = 0;
  var h3IdNum = 0;
  var listHtml = "";

  $('.docs-content').children('h2,h3').each(function(){
    if ( this.tagName.toLowerCase() == "h2") {
      h2IdNum++;

      var $h2 = $(this);
      var h2Text = $h2.text();
      var h2Id = "anchor-" + h2IdNum;

      if ( h3IdNum !== 0){
        listHtml += '</ul>';
      }

      // もしpugの方で予めidが付与されていたらそれを使う
      if ( $h2.attr('id') ) {
        h2Id = $h2.attr('id');
      }else{
        $h2.attr('id', h2Id);
      }

      listHtml += `<li><a href="#${h2Id}">${h2Text}</a></li>`;

      h3IdNum = 0;  // 常に初期化
    }else if ( this.tagName.toLowerCase() == "h3"){
      if ( h3IdNum === 0){
        listHtml += '<ul>';
      }
      h3IdNum++;
      var $h3 = $(this);
      var h3Text = $h3.text();
      var h3Id = "anchor-" + h2IdNum + "-" + h3IdNum;

      $h3.attr('id', h3Id);
      listHtml += `<li><a href="#${h3Id}">${h3Text}</a></li>`;
    }

  });

  // docs-indexのDOM生成
  var $docIndex = $(`<ul class="docs-index">${listHtml}</ul>`);  // これをh1の次に挿入する
  // h1の直後に要素を挿入
  $docIndex.insertAfter($h1);
}

function openExternalLink(){
  $(document).on('click', 'a', function(){
    var href = $(this).attr('href');
    // httpリンクで、framework7.jpでもない場合で、target="_blank" がついてない時
    if ( href.indexOf("http") !== -1 && href.indexOf("framework7.jp") === -1 && $(this).attr('target') !== "_blank"){
      $(this).attr('target', "_blank");
    }
  });
}

export default initFramework7Jp;
